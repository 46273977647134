<div class="wrapper" *ngIf="urls$ | async as urls">
	<a mat-raised-button color="primary" [disabled]="disabled" [href]="urls.google" (click)="onLogin()">
		<img class="icon" [class.disabled]="disabled" src="/icons/google-01.png" />
		<span>Google</span>
	</a>
	<!-- <a mat-raised-button color="primary" [disabled]="disabled" [href]="urls.facebook" (click)="onLogin()">
		<img class="icon" [class.disabled]="disabled" src="/icons/facebook-01.png" />
		<span>Facebook</span>
	</a> -->
</div>
